export const PRESETS = [
  {
    _id: crypto.randomUUID(),
    order: 1,
    contexts: ['all'],
    title: 'Faixa no topo com CTA e Cronômetro para finalizar a compra',
    description:
      'Implementação de uma Faixa no topo com um CTA (call to action) para finalizar a compra, quando houver um ou mais produtos no carrinho.',
    image:
      'https://cdn.usebeon.io/tenant/da05baec-9d3a-4b94-8e46-be236f245bcc/images/2eb1a2f0-aa87-43eb-baea-3a148a204d08/FaixaTopoComCTA.jpg',
    helperUrl:
      'https://beonly.notion.site/Faixa-no-topo-com-CTA-e-Cron-metro-para-finalizar-a-compra-d45360b5d4fb4b8cb32ca310a462376f',
    tags: [
      ['#1a1245', 'Exemplo'],
      ['#000', 'Black Friday'],
    ],

    element: {
      _id: crypto.randomUUID(),
      type: 'banner',

      props: {
        label: 'Faixa no topo com CTA e Cronômetro para finalizar a compra',
        isPreview: true,
      },

      template: {
        _id: 'cf7f92ca-4e9d-40eb-af76-f92a3bb3da4e',
        params: {
          layout: 'compacto',
          banner_headline:
            'Conclua a sua compra e garanta as ofertas e o estoque disponível!',
          cta_show: 'sim',
          cta_label: 'Finalizar a compra',
          event_label: 'faixa_topo_engajamento',
          dispatch_ga_events: true,
          session_unique_event: false,
          showcase_unique_event: false,
          clock_show: 'sim',
          clock_headline: ' ',
          clock_timer_type: 'interval',
          clock_interval: '15',
          clock_start_datetime: '2020-01-01T00:00:00',
          clock_end_datetime: '2020-12-31T00:00:00',
          clock_hide_on_end: 'true',
          clock_aggregate_days: 'true',
          clock_with_labels: 'sim',
          custom_banner_bg: '#00cc00',
          custom_banner_fg: '#FFFFF',
          custom_banner_justify: 'space-around',
          custom_banner_maxwidth: '100%',
          custom_headline_fg: '#FFF',
          custom_headline_align: 'center',
          custom_headline_fz: '1.2em',
          custom_cta_bg: 'transparent',
          custom_cta_fg: '#FFF',
          custom_cta_fz: '1em',
          custom_cta_border: '2px solid',
          custom_clock_bg: 'transparent',
          custom_clock_fg: '#FFF',
          custom_clock_fz: '14px',
          container_class: '',
          container_style: '',
        },
      },

      segmentation: [
        {
          _id: '8e99b8a6-b800-4919-9ea3-700f187265ec',
          variable: 'cart_items_count',
          operator: 'greater_than_equals_to',
          expected: '1',
        },
      ],
    },
  },

  {
    _id: crypto.randomUUID(),
    order: 2,
    contexts: ['catalog'],
    title: 'Destaque um produto na página de catálogo',
    description:
      'Destaque em seu catálogo produtos que queira evidenciar ou limpar o estoque.',
    image:
      'https://cdn.usebeon.io/tenant/f3edfb84-c436-40de-8f90-065df5be1f9c/images/35275fcb-4490-43d5-a45c-f5a5fbae62ab/DestaquesDaCategoria.jpg',
    helperUrl:
      'https://beonly.notion.site/Destaque-um-produto-na-p-gina-de-cat-logo-48c7f83c7b984eb1ae588d5ad6eed9ed',

    element: {
      _id: crypto.randomUUID(),
      type: 'showcase',

      props: {
        label: 'Destaques da categoria',
        isPreview: true,
      },

      engine: {
        _id: '66361f52-1824-4b0b-8466-cb906487c981',
        params: {
          limit: 16,
          tree_type: [],
          engine: '202beafa-966f-4cd1-9f7e-fe5713a76033',
          filters: {
            include_all: 'one',
            product_selection_key: 'none',
            product_selection_behavior: 'none',
            product_selection_ids: null,
            product_selection_keep_order: 'no',
            price_range_from: null,
            price_range_to: null,
            attributes: {
              is_promo: 'none',
              is_new: 'none',
              is_available: 'exclude',
            },
          },
        },
      },

      template: {
        _id: '438df6a4-fa33-486c-b3a7-b5c65a241b25',
        params: {
          layout: 'compact',
          width: 'container',
          event_label: 'catalog_highlights',
          container_class: '',
          container_style: '',
          headline: 'Destaques da categoria',
          lead: 'Separamos os melhores produtos para você:',
          cta_style: 'background:#fff; color: #666;',
          cta_buy_show: 'nao',
          cta_buy_label: 'Adicionar',
          cta_buy_style: 'primary',
          cta_buy_params: 'seller=1&redirect=true&sc=1',
          cta_details_show: 'sim',
          cta_details_label: 'Ver detalhes',
          cta_details_style: 'transparent',
          container_config:
            '{"autoplay": 4, "viewports":{"0":{"i": 1,"s": 1,"b": 0}}}',
          free_shipping_price: '0',
        },
      },

      segmentation: [],
    },
  },

  {
    _id: crypto.randomUUID(),
    order: 3,
    contexts: ['all'],
    title: 'Recomendações a partir da navegação de produto',
    description:
      'Vitrine trazendo recomendações a partir de um atributo do produto visitado. Excelente para expôr melhor seu catálogo e de maneira inteligente',
    image:
      'https://cdn.usebeon.io/tenant/f3edfb84-c436-40de-8f90-065df5be1f9c/images/ca205e54-371d-4f15-8d30-3fbf189f866f/RecomendadosParaVoce.jpg',
    helperUrl:
      'https://beonly.notion.site/Recomenda-es-a-partir-da-navega-o-de-produto-d4c32e7dce4f4bf9af5e78f88987b85c',

    element: {
      _id: crypto.randomUUID(),
      type: 'showcase',

      props: {
        label: 'Recomendados para você',
        isPreview: true,
      },

      template: {
        _id: '03002b07-f30e-4bf6-ab33-e91dcf4b4941',
        params: {
          title: 'Recomendados pra você',
          subtitle: 'Selecionados a partir dos seus interesses',
          target_label: ' ',
          event_label: 'continue_navegando',
          image_hover: 'nao',
          pivot_label: 'Você viu esse:',
          sliders_label: 'veja também:',
          navigation_type: 'button',
          navigation_button_label: 'Ver outro',
          cta_buy_show: 'nao',
          cta_buy_label: 'Adicionar',
          cta_buy_style: 'primary',
          cta_buy_params: 'seller=1&redirect=true&sc=1',
          cta_details_show: 'sim',
          cta_details_label: 'Ver detalhes',
          cta_details_style: 'primary',
          container_config:
            '{"viewports":{"0":{"i": 2,"s": 2,"b": 0},"1024":{"i": 3,"s": 1,"b": 0}}}',
          title_tag: 'span',
          container_class: '',
          container_style: '',
          free_shipping_badge_price: '0',
        },
      },

      engine: {
        _id: '5257158f-fd5a-498b-a286-0cedf677dba9',
        params: {
          limit: 16,
          tree_type: ['category'],
          tree_combine: [],
          engine: '0f765f85-3a4c-4990-b3dd-d1a13e310b53',
          filters: {
            include_all: 'one',
            product_selection_key: 'none',
            product_selection_behavior: 'none',
            product_selection_keep_order: 'no',
            attributes: {
              is_promo: 'none',
              is_new: 'none',
              is_available: 'exclude',
            },
          },
        },
      },

      segmentation: [],
    },
  },

  {
    _id: crypto.randomUUID(),
    order: 4,
    contexts: ['product'],
    title: 'Vitrine com produtos complementares',
    description:
      'Excelente opção para expor melhor o seu catálogo e realizar a retenção do usuário na sua loja.',
    image:
      'https://cdn.usebeon.io/tenant/f3edfb84-c436-40de-8f90-065df5be1f9c/images/9fbed794-4818-41ad-ad0e-7cc9c224f694/VejaMais.jpg',
    helperUrl:
      'https://beonly.notion.site/Vitrine-com-produtos-complementares-na-p-gina-de-cat-logo-e-produto-2092155998f1400cad2028dc31d0ac89',

    element: {
      _id: crypto.randomUUID(),
      type: 'showcase',

      props: {
        label: 'Veja também',
        isPreview: true,
      },

      template: {
        _id: '96048461-aff2-40d1-a9b1-f62125164cd7',
        params: {
          title: 'Veja também:',
          subtitle: 'O que outras pessoas viram ao ver este',
          target_label: ' ',
          event_label: 'product_qvvt',
          cta_buy_show: 'nao',
          cta_buy_label: 'Adicionar',
          cta_buy_style: 'primary',
          cta_buy_params: 'seller=1&redirect=true&sc=1',
          cta_details_show: 'sim',
          cta_details_label: 'Ver detalhes',
          cta_details_style: 'primary',
          stock_unavailable_badge_show: 'sim',
          stock_badge_show: 'nao',
          stock_badge_limit: '',
          price_discount_badge_show: 'sim',
          price_discount_badge_format: 'relative',
          price_special_badge_show: 'nao',
          price_special_badge_limit: '',
          free_shipping_badge_show: 'nao',
          free_shipping_badge_price: '',
          container_config:
            '{"viewports":{"0":{"i": 2,"s": 2},"768":{"i": 4,"s": 4,"b": 0}}}',
          title_tag: 'span',
          container_class: '',
          container_style: '',
        },
      },

      engine: {
        _id: '3fa7fc89-8c36-454c-b721-d98726291805',
        params: {
          limit: 16,
          tree_type: ['brand'],
          price_condition: 'none',
          engine: '0f765f85-3a4c-4990-b3dd-d1a13e310b53',
          filters: {
            include: [],
            include_all: 'one',
            product_selection_key: 'none',
            product_selection_behavior: 'none',
            product_selection_ids: null,
            product_selection_keep_order: 'no',
            price_range_from: null,
            price_range_to: null,
            attributes: {
              is_promo: 'none',
              is_new: 'none',
              is_available: 'exclude',
            },
          },
        },
      },

      segmentation: [],
    },
  },

  {
    _id: crypto.randomUUID(),
    order: 5,
    contexts: ['all'],
    title: 'Notificação para frete grátis',
    description:
      'Informe o benefício de frete grátis durante a navegação do usuário e dê um estímulo para ele incluir mais itens no carrinho, até atingir o valor determinado.',
    image:
      'https://cdn.usebeon.io/tenant/f3edfb84-c436-40de-8f90-065df5be1f9c/images/587d05a5-1298-40ca-a6fa-09fdfd788171/NotificacaoFreteGratis.jpg',
    helperUrl:
      'https://beonly.notion.site/Notifica-o-para-frete-gr-tis-d8b5b61d1a654e0db6e431c29be81be8',

    element: {
      _id: crypto.randomUUID(),
      type: 'notification',

      props: {
        label: 'Frete grátis',
        isPreview: true,
      },

      template: {
        _id: 'bf7f92ca-4e9d-40eb-af76-f92a3bb3da4e',
        params: {
          layout: 'horizontal',
          screen_position: '180deg',
          auto_dismiss: '15',
          allow_dismiss: '1',
          dismiss_style: 'times',
          dismiss_label: 'Dispensar',
          event_label: 'push_fretegratis',
          background_color: null,
          container_class: '',
          container_style: '',
          target_cart_subtotal: '199',
          stage1_icon: '🚚',
          stage1_headline: 'Tem frete grátis?',
          stage1_message:
            'Quase lá! Complete R$199,00 em compras e o <b>frete é por nossa conta.</b>',
          stage1_cta_message: 'Falta apenas',
          stage1_cta_label: 'Continuar comprando',
          stage1_cta_link: '#close',
          stage2_icon: '🥳',
          stage2_headline: 'Hoje tem frete grátis!',
          stage2_message:
            'Finalize sua compra hoje e garanta o frete grátis para compras acima de <b>R$199,00</b>',
          stage2_cta_label: 'Finalizar compra',
          stage2_cta_link: '/cart',
          clock_show: 'nao',
          clock_headline: 'Essas ofertas expiram em:',
          clock_timer_type: 'interval',
          clock_interval: '60',
          clock_start_datetime: '2020-01-01T00:00:00',
          clock_end_datetime: '2019-11-18T00:00:00',
          clock_hide_on_end: 'true',
          clock_aggregate_days: 'false',
          clock_with_labels: 'sim',
          custom_notf_body_bg: '#FFFFFF',
          custom_notf_body_fg: '#333',
          custom_notf_body_fz: '13px',
          custom_notf_cta_bg: '#000',
          custom_notf_cta_fg: '#FFF',
          custom_clock_bg: '#FF7678',
          custom_clock_fg: '#000000',
          custom_clock_fz: '13px',
        },
      },

      segmentation: [
        {
          _id: 'b36af70a-16ef-4ca3-9940-e8f774a9381d',
          variable: 'navigation_page_type',
          operator: 'is_in',
          expected: 'homepage,catalog,product',
        },
      ],
    },
  },

  {
    _id: crypto.randomUUID(),
    order: 6,
    contexts: ['cart'],
    title: 'Aproveite e leve junto: compra por impulso no carrinho',
    description:
      'Implementação de uma Vitrine Inteligente na página de carrinho para promover produtos adicionais.',
    image:
      'https://cdn.usebeon.io/tenant/f3edfb84-c436-40de-8f90-065df5be1f9c/images/7e545921-1b63-4df3-ae8c-886f4d43f0b4/AproveiteLeveJunto.jpg',
    helperUrl:
      'https://beonly.notion.site/Aproveite-e-leve-junto-compra-por-impulso-no-carrinho-92672aac64cd49bebc194e0b1902f2a4',

    element: {
      _id: crypto.randomUUID(),
      type: 'showcase',

      props: {
        label: 'Aproveite e leve junto',
        isPreview: true,
      },

      template: {
        _id: 'de540b3f-f6df-41e3-906b-b91234f88458',
        params: {
          title: 'Aproveite e leve junto',
          target_label: ' ',
          event_label: 'cart_levejunto',
          cta_buy_show: 'sim',
          cta_buy_label: 'Adicionar',
          cta_buy_style: 'primary',
          cta_buy_params: 'seller=1&redirect=true&sc=1',
          cta_details_show: 'nao',
          cta_details_label: 'Ver detalhes',
          cta_details_style: 'primary',
          stock_unavailable_badge_show: 'sim',
          stock_badge_show: 'nao',
          stock_badge_limit: '',
          price_discount_badge_show: 'sim',
          price_discount_badge_format: 'relative',
          price_special_badge_show: 'nao',
          price_special_badge_limit: '',
          free_shipping_badge_show: 'nao',
          free_shipping_badge_price: '',
          container_config:
            '{"viewports":{"0":{"i": 1,"s": 1},"768":{"i": 3,"s": 3,"b": 0}}}',
          title_tag: 'span',
          container_class: '',
          container_style: '',
        },
      },

      engine: {
        _id: '202beafa-966f-4cd1-9f7e-fe5713a76033',
        params: {
          limit: 16,
          filters: {
            include_all: 'one',
            product_selection_key: 'none',
            product_selection_behavior: 'none',
            product_selection_ids: null,
            product_selection_keep_order: 'no',
            price_range_from: null,
            price_range_to: null,
            attributes: {
              is_promo: 'none',
              is_new: 'none',
              is_available: 'exclude',
            },
          },
        },
      },

      segmentation: [],
    },
  },

  {
    _id: crypto.randomUUID(),
    order: 7,
    contexts: ['product'],
    title: 'Vitrines com produtos complementares',
    description:
      'Aumente seu ticket médio recomendando itens que são complementares ao que está sendo visto na página de produto.',
    image:
      'https://cdn.usebeon.io/tenant/f3edfb84-c436-40de-8f90-065df5be1f9c/images/8d439f29-cef2-4ad7-a254-94e18419eb17/VejaTambem.jpg',
    helperUrl:
      'https://beonly.notion.site/Vitrine-com-produtos-complementares-dc36424b0d9848c79fa9d03aea6622d6',

    element: {
      _id: crypto.randomUUID(),
      type: 'showcase',

      props: {
        label: 'Veja também',
        isPreview: true,
      },

      template: {
        _id: '96048461-aff2-40d1-a9b1-f62125164cd7',
        params: {
          title: 'Veja também:',
          subtitle: 'O que outras pessoas viram ao ver este',
          target_label: 'Veja mais',
          event_label: 'product_qvvt',
          cta_buy_show: 'nao',
          cta_buy_label: 'Adicionar',
          cta_buy_style: 'primary',
          cta_buy_params: 'seller=1&redirect=true&sc=1',
          cta_details_show: 'sim',
          cta_details_label: 'Ver detalhes',
          cta_details_style: 'primary',
          stock_unavailable_badge_show: 'sim',
          stock_badge_show: 'nao',
          stock_badge_limit: '',
          price_discount_badge_show: 'sim',
          price_discount_badge_format: 'relative',
          price_special_badge_show: 'nao',
          price_special_badge_limit: '',
          free_shipping_badge_show: 'nao',
          free_shipping_badge_price: '',
          container_config:
            '{"viewports":{"0":{"i": 2,"s": 2},"768":{"i": 4,"s": 4,"b": 0}}}',
          title_tag: 'span',
          container_class: '',
          container_style: '',
        },
      },

      engine: {
        _id: '3fa7fc89-8c36-454c-b721-d98726291805',
        params: {
          limit: 16,
          tree_type: ['brand'],
          price_condition: 'none',
          engine: '0f765f85-3a4c-4990-b3dd-d1a13e310b53',
          filters: {
            include: [],
            include_all: 'one',
            product_selection_key: 'none',
            product_selection_behavior: 'none',
            product_selection_ids: null,
            product_selection_keep_order: 'no',
            price_range_from: null,
            price_range_to: null,
            attributes: {
              is_promo: 'none',
              is_new: 'none',
              is_available: 'exclude',
            },
          },
        },
      },

      segmentation: [],
    },
  },

  {
    _id: crypto.randomUUID(),
    order: 9,
    contexts: ['product'],
    title: 'Cronômetro em um produto',
    description: '',
    image:
      'https://cdn.usebeon.io/tenant/f3edfb84-c436-40de-8f90-065df5be1f9c/images/79a912db-6020-4b57-ae4b-67688762cfdc/CronometroCTA.jpg',
    helperUrl:
      'https://beonly.notion.site/Cron-metro-em-um-produto-9dbd749b11a64291975e23ed208e1d38',

    element: {
      _id: crypto.randomUUID(),
      type: 'banner',

      props: {
        label: 'Cronômetro CTA',
        isPreview: true,
      },

      template: {
        _id: 'cf7f92ca-4e9d-40eb-af76-f92a3bb3da4e',
        params: {
          event_label: 'cta_add-to-cart',
          layout: 'vertical',
          cta_show: 'nao',
          cta_label: 'Eu quero!',
          cta_link: '',
          clock_show: 'sim',
          clock_headline: 'Preço especial disponível por:',
          clock_timer_type: 'interval',
          clock_interval: '60',
          clock_start_datetime: '2020-01-01T00:00:00',
          clock_end_datetime: '2020-12-31T00:00:00',
          clock_hide_on_end: 'true',
          clock_aggregate_days: 'true',
          clock_with_labels: 'sim',
          custom_banner_bg: '#000',
          custom_banner_fg: '#FFF',
          custom_banner_justify: 'space-around',
          custom_banner_maxwidth: '100%',
          custom_headline_fg: '#FFF',
          custom_headline_align: 'center',
          custom_headline_fz: '1.5em',
          custom_cta_bg: 'transparent',
          custom_cta_fg: '#FFF',
          custom_cta_fz: '1em',
          custom_cta_border: '2px solid',
          custom_clock_bg: 'transparent',
          custom_clock_fg: '#FFF',
          custom_clock_fz: '14px',
        },
      },

      segmentation: [],
    },
  },
];
