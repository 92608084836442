import createConsumer from '../../rest/createConsumer';
import { PRESETS } from './presets';

const PresetsApiStaticConsumer = createConsumer(() => {
  const getPresets = () => {
    return PRESETS;
  };

  return Object.freeze({
    getPresets,
  });
});

export default PresetsApiStaticConsumer;
