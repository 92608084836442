import React from 'react';
import { Button, Row, Col, message } from 'antd';

import EmptyComponent from '../../../../Common/Empty/Empty';
import ElementsList from '../../../../Common/Element/List';
import ElementRow from '../../../../Common/Element/Row';
import ElementActionEdit from '../../../../Common/Element/Actions/Edit';
import ElementActionClone from '../../../../Common/Element/Actions/Clone';
import ElementActionDelete from '../../../../Common/Element/Actions/Delete';
import ElementActionVisibility from '../../../../Common/Element/Actions/Visibility';
import { generateLog } from '../../../../../domain/Logs';

import './styles/list.css';
import './styles/element.css';
import { PlusOutlined } from '@ant-design/icons';
import ElementActionPreview from '../../../../Common/Element/Actions/Preview';

export default class PageElementsListContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      elements: props.elements,
      region: props.region,
    };

    this.store = props.store;
    this.client_id = props.client_id;
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      elements: nextProps.elements,
      region: nextProps.region,
    });
  }

  setLog = (resource, action_type) => {
    const user_email = JSON.parse(
      localStorage.getItem('current_user_meta'),
    ).email;
    const user_id = JSON.parse(localStorage.getItem('current_user_meta'))._id;

    const log = {
      app_location: 'Beon Studio',
      tenant_id: this.client_id,
      time_stamp: new Date().toJSON(),
      user_email,
      user_id,
      resource,
      action_type,
    };

    generateLog(log);
  };

  handleAddElement(event) {
    event.preventDefault();

    this.store.emitter.dispatch(
      this.store.emitter.ELEMENT_ADD_START,
      this.state.region,
    );
  }

  // element actions handlers
  handleElementEdit = element =>
    this.store.emitter.dispatch(this.store.emitter.ELEMENT_EDIT_START, element);

  handleElementDelete = element => {
    this.store.deleteElement(element._id);
    message.success(`Elemento removido da página.`);
    const pageRegion = this.store.getPage().context.name;
    const pageName = this.store.getPage().props.label;

    let label = '';

    if (element.label) {
      label = element.label;
    } else {
      label = element.props.label;
    }

    this.setLog(`${pageRegion}-${pageName}-${label}`, 'Elemento deletado');
  };

  handleElementPreview = element => {
    const isCurrentlyInPreview = element?.props?.isPreview ? true : false;
    element.props.isPreview = !isCurrentlyInPreview;

    this.store.saveElement(element);

    message.success(
      isCurrentlyInPreview
        ? 'O elemento está visível para todos os usuários.'
        : 'O elemento está visível somente em modo de pré-visualização.',
    );
  };

  handleElementVisibility = element => {
    element.props.isHidden = !element.props.isHidden;

    const now = new Date().toISOString();

    if (element.props.isHidden) {
      element.hidden_at = now;
    } else {
      element.visible_at = now;
    }

    this.store.saveElement(element);
    message.success(`A visibilidade do elemento foi ajustada.`);
    const pageRegion = this.store.getPage().context.name;
    const pageName = this.store.getPage().props.label;

    this.setLog(
      `${pageRegion}-${pageName}-${element.props.label}`,
      'Visibilidade do elemento ajustada',
    );
  };

  handleElementClone = async element => {
    const clone = await this.store.cloneElement(element._id);
    this.handleElementEdit(clone);
  };

  handleElementMove = (element, direction) => {
    let { elements } = this.state;

    // get region elements and sort by its order prop
    elements = elements.sort((a, b) => a.props.order - b.props.order);

    // define elements new index
    const current_index = elements.indexOf(element);
    const target_index =
      direction === 'up' ? current_index - 1 : current_index + 1;

    const new_index =
      target_index > elements.length
        ? elements.length
        : target_index < 0
        ? 0
        : target_index;

    // update elements position
    elements.splice(new_index, 0, elements.splice(current_index, 1)[0]);

    // finally change order prop
    elements.map((element, index) => {
      element.props.order = index + 1;
      return element;
    });

    this.store.saveElement(element);

    this.setState({ elements });

    let formattedDirection = direction === 'up' ? 'cima' : 'baixo';
    const pageRegion = this.store.getPage().context.name;
    const pageName = this.store.getPage().props.label;

    this.setLog(
      `${pageRegion}-${pageName}-${element.props.label}`,
      `Orden alterada para ${formattedDirection}`,
    );
  };

  render() {
    return (
      <div className="page-elements-grid">
        <ElementsList
          elements={this.state.elements}
          empty={
            <EmptyComponent
              description={<span>Sentindo falta de alguma coisa?</span>}
              action={<span>Adicionar elemento</span>}
              buttonProps={{
                icon: <PlusOutlined />,
                type: 'primary',
                onClick: this.handleAddElement.bind(this),
              }}
            />
          }
          addItem={
            <Row className="actions-row">
              <Col span={24}>
                <Button
                  type="primary"
                  shape="round"
                  icon={<PlusOutlined />}
                  onClick={this.handleAddElement.bind(this)}
                >
                  Adicionar elemento
                </Button>
              </Col>
            </Row>
          }
        >
          {element => (
            <ElementRow
              key={element._id}
              element={element}
              handleElementMove={this.handleElementMove}
              actions={[
                <ElementActionVisibility
                  key={`${element._id}:action:visibility`}
                  element={element}
                  handle={event => this.handleElementVisibility(element)}
                />,
                <ElementActionPreview
                  key={`${element._id}:action:preview`}
                  element={element}
                  handle={() => this.handleElementPreview(element)}
                />,
                <ElementActionEdit
                  key={`${element._id}:action:edit`}
                  element={element}
                  handle={event => this.handleElementEdit(element)}
                />,
                <ElementActionClone
                  key={`${element._id}:action:clone`}
                  element={element}
                  handle={event => this.handleElementClone(element)}
                />,
                <ElementActionDelete
                  key={`${element._id}:action:delete`}
                  element={element}
                  handle={event => this.handleElementDelete(element)}
                />,
              ]}
            />
          )}
        </ElementsList>
      </div>
    );
  }
}
