import React from 'react';
import { Button, Card, Tag, Tooltip } from 'antd';
import HelperPopup from '../Popup/HelperPopup';

import './presetsCard.css';

const { Meta } = Card;

const getTooltipText = ({ contexts, isPresetAvailableInThisPage }) => {
  if (isPresetAvailableInThisPage) return '';

  const availablePages = [];

  contexts.forEach(context => {
    switch (context) {
      case 'homepage':
        availablePages.push('Homepage');
        break;

      case 'product':
        availablePages.push('Produto');
        break;

      case 'catalog':
        availablePages.push('Catálogo');
        break;

      case 'cart':
        availablePages.push('Carrinho');
        break;

      case 'search':
        availablePages.push('Busca');
        break;

      case 'notfound':
        availablePages.push('404');
        break;

      default:
        break;
    }
  });

  let tooltipText;
  if (availablePages.length === 1) {
    tooltipText = `Template disponível na página de ${availablePages[0]}.`;
  } else {
    const allPagesButLast = availablePages.slice(0, -1).join(', ');
    const lastPage = availablePages[availablePages.length - 1];

    tooltipText = `Template disponível nas páginas de ${
      availablePages.length === 2
        ? availablePages.join(' e ')
        : `${allPagesButLast} e ${lastPage}`
    }.`;
  }

  return tooltipText;
};

const PresetsCard = ({ addPreset, preset, store }) => {
  const {
    _id,
    contexts = [],
    description,
    helperUrl,
    image,
    tags = [],
    title,
  } = preset;
  const currentContext = store?.page?.context?.name || 'all';

  const isPresetAvailableInThisPage =
    contexts.includes('all') || contexts.includes(currentContext);

  const actions = () => {
    const presetActions = [
      <Tooltip
        title={getTooltipText({ contexts, isPresetAvailableInThisPage })}
      >
        <Button
          disabled={!isPresetAvailableInThisPage}
          onClick={() => addPreset(preset)}
        >
          Utilizar modelo
        </Button>
      </Tooltip>,
    ];

    if (helperUrl) {
      presetActions.push(
        <HelperPopup
          className={'presets-card__helper'}
          mode={'text'}
          text={'Saiba mais'}
          url={helperUrl}
        />,
      );
    }

    return presetActions;
  };

  const Description = () => {
    return (
      <>
        <p>{description}</p>
        <p>
          {tags.map(([color, label]) => {
            return (
              <Tag
                key={`tag-${_id}-${label}`}
                className="presets-card__tag"
                color={color}
              >
                {label}
              </Tag>
            );
          })}
        </p>
      </>
    );
  };

  return (
    <Card
      className="presets-card"
      cover={<img alt={title} src={image} />}
      actions={actions()}
    >
      <Meta title={title} description={<Description />} />
    </Card>
  );
};

export default PresetsCard;
